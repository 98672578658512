<template>
  <div @contextmenu.prevent @keydown.prevent="disableConsole">
    <!--  <div>-->
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  // 禁止打开控制台功能
  methods: {
    disableConsole(event) {
      // event.key === 'F12' ||
      if ((event.ctrlKey && event.shiftKey && event.key === 'I')) {
        event.preventDefault();
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.disableConsole);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.disableConsole);
  }
}
//{ "Action":"SwitchWebcast", "Data":"{ \"UserName\":\"diybyholly\",\"key\":\"123456\" }" }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #1b1b1f;
  min-width: 1000px;
  min-height: 500px;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>
