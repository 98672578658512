<template>
  <div class="hello">

    <div class="left">
      <div class="logo_div">
        Star Game
      </div>
      <div class="slgon">
        祝您开播愉快
      </div>
      <div class="game_log_list">
        <div class="game_log_list_text">我的热门游戏库 <br style="font-size: 7px"> My popular game library </div>
        <el-scrollbar height="500px" style="margin-top: 10px">
          <div class="game_log_list_content">
            <div v-for="item in gameLogList" :key="item" class="game_log_list_div" @click="go(item)">
              <div style="height: 60px;width:50%;">
                <el-image class="hot_game_log_list_image" style="pointer-events: none;" :src="item.game_image_Circle"
                          fit="cover"/>
              </div>
              <div class="hot_game_log_list_title">
                <p v-text="item.game_title"></p>
                <p v-text="item.game_title_en" style="font-size: 10px"></p>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!--    style="pointer-events: none;"-->
    <div class="right">
      <div class="nav_box">
        <ul>
          <li :class="{ 'nav_active':activeName===1 }" @click="navClick(1)">首页（Home）</li>
          <li @click="navClick(2)">充值（Pay）</li>
        </ul>
      </div>
      <div v-if="activeName===1" class="banner" style="border-radius: 10px;overflow: hidden">
        <el-carousel height="250px" motion-blur>
          <el-carousel-item v-for="item in bannerList" :key="item">
            <el-image class="game_log_list_image" style="width: 100%;height: 100%; "
                      :src="item.image_url"
                      fit="cover"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <el-scrollbar v-if="activeName===1" height="500px">
        <!--        <div class="game_title_one">各区时间(Time)</div>-->
        <!--        <div class="time">-->
        <!--          <div v-for="item in time" :key="item" class="time_div">-->
        <!--            <div class="time_title" v-text="item.title"></div>-->
        <!--            <div class="time_time" v-text="item.time.hour+':'+item.time.min+':'+item.time.sec"></div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="game_title_one">热播游戏(Game)</div>
        <div class="game_list">
          <div v-for="item in gameList" :key="item" class="game_div" @click="go(item)">
            <el-image class="game_image" style="pointer-events: none;border-radius: 10px" :src="item.game_image_url"
                      fit="cover"/>
            <div class="game_title" style="display: flex;flex-flow: column">
              <p v-text="item.game_title"></p>
              <p v-text="item.game_title_en"></p>
            </div>
          </div>
        </div>
      </el-scrollbar>

    </div>

    <!--    选择游戏后侧边栏-->
    <el-drawer v-model="drawer" direction="rtl" style="background: #1b1b1f" @close="closeDrawer()"
               :before-close="handleClose">
      <template #header>
        <div class="drawer_title">

          <p v-text="lookGameData.game_title"></p>
          <p v-text="lookGameData.game_title_en"></p>
        </div>


      </template>
      <template #default>
        <div style="pointer-events: none;">
          <el-image class="drawer_image" :src="lookGameData.game_image_url"
                    fit="cover"/>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <div v-if="progress >= 0" style="color: #ffffff;">
            <p>Download progress: {{ progress }}%</p>
            <progress :value="progress" max="100"></progress>
          </div>
          <div v-if="progress_text" style="color: #ffffff;width: 100%;text-align: center">
            <p>等待解压</p>
          </div>
          <div v-if="Decompression_progress >= 0" style="color: #ffffff;">
            <p>Decompression progress: {{ Decompression_progress }}%</p>
            <progress :value="Decompression_progress" max="100"></progress>
          </div>
          <el-button type="success" @click="download(true)" v-if="downloadJudge">下载(Download)</el-button>
          <el-button type="success" @click="download(false)" v-if="updateJudge">更新(Update)</el-button>
          <el-button type="success" v-if="liveJudge" @click="goLive">开播(Start)</el-button>
        </div>
      </template>
    </el-drawer>

    <!--    开播控制面板-->
    <el-drawer :show-close="false" v-model="live_drawer" direction="ttb" size="100%" :modal="false"
               style="background: #1b1b1f">
      <div class="live_drawer">
        <div class="live_drawer_game_time">
          <div v-for="item in time" :key="item" class="time_div">
            <div class="time_title" v-text="item.title"></div>
            <div class="time_time" v-text="item.time.hour+':'+item.time.min+':'+item.time.sec"></div>
          </div>
        </div>
        <div class="live_drawer_game_title">
          当前开播游戏：<span v-text="lookGameData.game_title"></span><span v-text="'('+lookGameData.game_title_en+')'"></span>
        </div>
        <div class="live_drawer_game_title" v-if="lookGameData.toolTrue==='true'">

          当前开播状态：<span v-text="tool_statue"></span>
        </div>
        <div class="close_div">
          <div class="close_div_div" @click="navClick(2)">充值<br/> (Pay)</div>
          <div class="close_div_div" @click="restart">重启工具及游戏<br/> (Restart tools and games)</div>
          <div class="close_div_div" @click="closeLive">关播<br/>(Close Live)</div>
        </div>
      </div>
    </el-drawer>
    <!--    <img src="../assets/RiCustomerService2Fill.png" class="custom_image" @click="custom_qrcode">-->
    <div class="game_file">
      <div @click="openGameFile">
        <el-tooltip class="item" effect="dark" content="点击打开游戏目录" placement="top-end">
          <img src="../assets/MaterialSymbolsFolderCopyRounded.png" class="custom_image_image">
        </el-tooltip>
      </div>
    </div>
    <!--   客服-->
    <div class="custom_image">
      <el-popover
          :width="250"
          popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
      >
        <template #reference>
          <img src="../assets/RiCustomerService2Fill.png" class="custom_image_image">
        </template>
        <template #default>
          <div
              class="demo-rich-conent"
              style="display: flex; gap: 16px; flex-direction: column"
          >
            <div>
              <p
                  class="demo-rich-content__name"
                  style="margin: 0; font-weight: 500"
              >
                微信(wechat)
              </p>
            </div>
            <p class="demo-rich-content__desc"
               style="margin: 0;width: 100%;display: flex;align-items: center;justify-content: center">
              <img src="../assets/qrcode.jpg" style="width: 200px;height: auto;pointer-events: none;">
            </p>
          </div>
        </template>
      </el-popover>
    </div>
    <!--    版本更新-->
    <div class="update" v-if="updateStatus">
      <div class="update_div">
        <img src="../assets/updateVersion.png" style="pointer-events: none;">
        <div style="font-weight: bold">
          修复bug（Fix bug）
        </div>
        <div class="update_btn" @click="updateTools">
          <div v-text="updateText"></div>
          <div v-if="download_progress!==-1" v-text="download_progress+'%'"></div>
        </div>
        <div v-if="download_progress_show" style="font-size: 12px;color: red;font-weight: bold;margin-top: 10px;">
          下载中，请耐心等候~（Please wait patiently while downloading ~）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {timeBox} from "js-tool-big-box";
import {httpRequest} from '@/api';
import {ElMessage, ElMessageBox} from 'element-plus';

export default {
  name: 'HelloWorld',
  data() {
    return {
      value: Date.now() + 1000 * 60 * 60 * 7,
      time: [],
      drawer: false,
      live_drawer: false,
      lookGameData: {},
      bannerList: [],
      gameList: [],
      gameLogList: [],
      tool_statue: "等待工具开启中……",
      ws: null,
      downloadJudge: false, // false 代表无需下载
      liveJudge: false, // false 代表无法开播（未下载游戏或者需要更新游戏）
      updateJudge: false, // false 代表无需更新
      progress: -1, // 初始进度为 -1 表示尚未开始下载
      Decompression_progress: -1,// 解压进度
      isMessageListenerAdded: false, // 是否已经添加消息监听器
      progress_text: false,
      updateText: "点击更新(Click update)",
      updateStatus: false, //是否更新
      version: "",
      download_progress: -1,//下载工具进度
      download_progress_show: false,//下载工具提示文字
      activeName: 1// 当前激活的标签页
    }
  },
  mounted() {
    // 从 URL 获取 secret 参数，如果没有则跳转到指定网址
    const urlParams = new URLSearchParams(window.location.search);
    let secret = urlParams.get("secret");
    if (!secret) {
      window.location.href = "https://www.zghbqn.com";
    }
    // 将空格替换为加号，存入 localStorage
    secret = secret.replace(/ /g, '+');
    window.localStorage.setItem("ticket", secret);


    // 初始化页面时获取 banner 图和游戏列表
    this.getBanner();
    this.getGame();
    this.liveGameLogGet();

    this.getVersion();
    // this.updateStatus = true
  },
  created() {
    // 创建时获取世界各地时间，并每秒更新一次
    this.getContinentalTimes();
    setInterval(this.getContinentalTimes, 1000);
  },
  methods: {
    navClick(item) {
      if (item === 2) {
        window.open("https://pay.xyhy.live", '_blank');
      } else {
        this.activeName = item
      }
      console.log(this.activeName)
    },
    handleClose(done) {
      if (this.Decompression_progress !== -1 || this.progress !== -1) {
        this.$message({
          message: '请等待游戏下载完成~',
          type: 'warning'
        });
        // this.$alert('是否取消下载该游戏？', '通知', {
        //   confirmButtonText: '确定',
        //   showCancelButton: true,
        //   cancelButtonText: "继续下载",
        //   callback: action => {
        //     console.log(action)
        //
        //     if (action === "confirm") {
        //       that.Decompression_progress = -1
        //       that.progress = -1
        //       that.progress_text = false
        //       that.cSend("CancelDownload", null);
        //       done()
        //     }
        //   }
        // });
      } else {
        done()
      }

    },
    closeDrawer() {
      console.log("关闭")
    },
    // 发送消息到 WebView
    cSend(code, con) {
      if (window.chrome && window.chrome.webview) {
        console.log("send success");
        window.chrome.webview.postMessage(JSON.stringify({type: code, con: con}));
      } else {
        console.error('WebView2 not available');
      }
    },
    judgeUpdate() {
      httpRequest("post", "update_tool", {
        version: this.version
      }).then((res) => {
        console.log(res)
        this.updateStatus = res.data.show
      });
    },
    //更新工具
    updateTools() {
      if (this.download_progress !== -1) {
        this.download_progress_show = true
      } else {
        this.cSend("updateTool", null);
        window.chrome.webview.addEventListener('message', this.handleMessage.bind(this));
      }
    },
    // 打开游戏目录
    openGameFile() {
      this.cSend("openGameFile", null);
    },
    // 重启工具及游戏
    restart() {
      // this.cSend("restartLiveTool", `${this.lookGameData.dir_name}/${this.lookGameData.game_exe_name}`);
      if (window.chrome && window.chrome.webview) {
        console.log("send success");
        window.chrome.webview.postMessage(JSON.stringify({
          type: "restartLiveTool",
          con: `${this.lookGameData.dir_name}/${this.lookGameData.game_exe_name}`,
          game_process: this.lookGameData.game_process,
        }));
      } else {
        console.error('WebView2 not available');
      }
    },
    // 关闭直播工具
    closeLive() {

      if (window.chrome && window.chrome.webview) {
        console.log("send success");
        window.chrome.webview.postMessage(JSON.stringify({
          type: "closeLiveTool",
          con: this.lookGameData.game_process,
          toolShow: this.lookGameData.toolTrue
        }));
      } else {
        console.error('WebView2 not available');
      }

      this.getGame();

      this.live_drawer = false;
      if (this.ws) {
        this.ws.close();
        ElMessage({
          type: 'info',
          message: '关播成功',
        });
      }
    },
    // 获取游戏列表
    getGame() {
      httpRequest("get", "game", {}).then(res => {
        this.gameList = res.data.data;
      });
    },
    // 获取 banner 图列表
    getBanner() {
      httpRequest("get", "banner", {}).then(res => {
        this.bannerList = res.data.data;
      });
    },
    // 点击游戏打开
    go(e) {
      if (!e.dir_name) {
        e.dir_name = "null";
      }
      this.cSend("selectGame", e.dir_name);
      this.lookGameData = e;
      // 添加消息监听器，确保只添加一次
      if (!this.isMessageListenerAdded) {
        window.chrome.webview.addEventListener('message', this.handleMessage.bind(this));
        this.isMessageListenerAdded = true;
      }
    },
    //获取版本号
    getVersion() {
      this.cSend("getVersion", null);
      window.chrome.webview.addEventListener('message', this.handleMessage.bind(this));
    },
    // 处理消息事件
    handleMessage(event) {
      const message = event.data;
      try {
        const jsonObject = JSON.parse(message);
        if (jsonObject.type === 'selectGame') {
          this.processSelectGameMessage(jsonObject);
        } else if (jsonObject.type === "updateTool") {
          console.log(jsonObject)
          this.updateText = jsonObject.exists
        } else if (jsonObject.type === "version") {
          this.version = jsonObject.exists
          console.log(this.version)
          this.judgeUpdate();
        } else if (jsonObject.type === "downloadToolUpdate") {
          this.download_progress = jsonObject.progress
          if (this.download_progress === 100) {
            this.download_progress = -1
          }
          console.log(jsonObject)
        }
      } catch (error) {
        console.error('Invalid JSON message received:', message);
      }
    },
    // 处理选择游戏消息
    processSelectGameMessage(jsonObject) {
      const dirName = this.lookGameData.dir_name;
      console.log('Folder exists:', jsonObject);
      console.log('dirName:', dirName);

      if (jsonObject.exists) {
        this.downloadJudge = false;
        if (jsonObject.textName == null) {
          this.liveJudge = jsonObject.exists;
          this.updateJudge = false;
        } else {
          this.liveJudge = false;
          this.updateJudge = true;
        }
      } else {
        this.downloadJudge = true;
        this.liveJudge = false;
        this.updateJudge = false;
      }

      this.drawer = true;
      this.lookGameData = {...this.lookGameData, ...jsonObject};
    },
    // 下载游戏
    download(judge) {
      if (!this.lookGameData.game_download_url) {

        this.$message({
          message: '该游戏暂未提供下载，请自行下载后，复制到游戏目录~（The game has not been downloaded yet, please download it yourself and copy it to the game directory ~）',
          type: 'warning'
        });
      }

      if (this.Decompression_progress !== -1 || this.progress !== -1) {
        this.$message({
          message: '正在下载中，请耐心等候~（Downloading, please be patient ~）',
          type: 'warning'
        });
      } else {
        if (window.chrome && window.chrome.webview) {
          //下载
          if (judge) {
            window.chrome.webview.postMessage(JSON.stringify({
              type: 'downloadGame',
              con: this.lookGameData.game_download_url
            }));
          } else {
            //更新
            window.chrome.webview.postMessage(JSON.stringify({
              type: 'UpdateGame',
              con: this.lookGameData.game_download_url,
              other: this.lookGameData.dir_name
            }));
          }


          this.listenForProgress();
        } else {
          console.error('WebView2 not available');
        }
      }


    },
    // 监听下载进度
    listenForProgress() {
      window.chrome.webview.addEventListener('message', (event) => {
        let message = event.data;
        // console.log(message)
        if (message) {
          message = JSON.parse(message);
        }

        if (message.type === "download") {
          this.progress = parseInt(message.progress);
          if (this.progress === 100) {
            this.progress_text = true
            this.progress = -1;
          }
        }
        if (message.type === "decompression") {
          this.Decompression_progress = parseInt(message.progress);
          if (this.Decompression_progress === 1) {
            this.progress_text = false
          }
          if (this.Decompression_progress === 100) {
            this.updateJudge = false
            this.downloadJudge = false

            this.liveJudge = true
            this.Decompression_progress = -1;
          }
        }
      });
    },
    // 判断 WebSocket 连接
    wsJudge() {
      let that = this
      this.ws = new WebSocket("ws://127.0.0.1:5678");
      this.ws.onopen = () => {
        console.log("ws open");
        this.tool_statue = "工具启动成功，等待游戏连接中……";
      }
      this.ws.onmessage = (event) => {
        const getData = JSON.parse(event.data);
        if (getData.Flag == 0) {
          this.tool_statue = getData.Msg;
        }
        if (getData.Type == 9) {
          this.tool_statue = "游戏连接成功，祝您开播愉快~~~" + getData.user.nickname;
          httpRequest("post", "liveLog", {
            "avatarLarger": getData.user.avatarLarger,
            "nickname": getData.user.nickname,
            "uniqueId": getData.user.uniqueId,
            "roomId": getData.user.roomId,
            "game_id": that.lookGameData.id
          }).then((res) => {
            console.log(res)
          });
        }
        //传输数据至服务器
        if (getData.Type == 4) {
          console.log(getData);
          let inData = {
            "game_id": that.lookGameData.id,
            "room_id": getData.Data.RoomId,
            "user_id": getData.Data.Uid,
            "nick_name": getData.Data.NickName,
            "sec_uid": getData.Data.secUid,
            "avatar": getData.Data.Avatar,
            "gift_id": getData.Data.GiftId,
            "gift_name": getData.Data.GiftName,
            "combocount": getData.Data.ComboCount,
            "git_type": getData.Data.GiftType,
            "gift_price": getData.Data.GiftPrice,
            "RepeatEnd": getData.Data.RepeatEnd,
            "GroupId": getData.Data.GroupId
          }
          httpRequest("post", "gameGiftLog", inData).then((res) => {
            console.log(res)
          });
        }
      }
      this.ws.onclose = () => {
        console.log("ws close");
        this.tool_statue = "工具连接断开，请检查网络连接……,点击重启工具";
      }
    },
    // 获取游戏直播日志列表
    liveGameLogGet() {
      httpRequest("get", "gameLiveLogGet", {}).then(res => {
        this.gameLogList = res.data.data;
      });
    },
    // 添加游戏直播日志
    liveGameLog() {
      httpRequest("post", "gameLiveLog", {
        game_id: this.lookGameData.id
      }).then(() => {
        this.liveGameLogGet();
      });
    },
    // 准备开播
    goLive() {
      let that = this;
      ElMessageBox.confirm('您已经准备好开播了吗？（Are you ready to start live?）', '通知(notice)', {
        confirmButtonText: '确定(Yes)',
        showCancelButton: false,
        confirmButtonClass: "new_button"
      }).then(res => {
        if (res == "confirm") {
          this.drawer = false;
          if (window.chrome && window.chrome.webview) {
            window.chrome.webview.postMessage(JSON.stringify({
              type: 'openLiveTool',
              con: `${this.lookGameData.dir_name}/${this.lookGameData.game_exe_name}`,
              toolShow: this.lookGameData.toolTrue
            }));
            that.liveGameLog();
            that.live_drawer = true;
            setTimeout(function () {
              that.wsJudge()
            }, 8000)
          } else {
            console.error('WebView2 not available');
          }
        }
      }).catch((res) => {
        console.log(res)
      });
    },
    // 获取世界各地时间
    getContinentalTimes() {
      const time = timeBox.getWorldTime();
      this.time = [
        {time: time.beijing, title: "北京（Beijing）"},
        {time: time.jakarta, title: "雅加达（Jakarta）"},
        {time: time.london, title: "伦敦（London）"},
        {time: time.newYork, title: "纽约（NewYork）"},
        {time: time.paris, title: "巴黎（Paris）"},
        {time: time.sydney, title: "悉尼（Sydney）"},
        {time: time.tokyo, title: "东京（Tokyo）"}
      ];
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.new_button {
  background: #84D02F !important;
  border-color: #84D02F !important;
}

.hello {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}


.left {
  width: 18%;
  height: 100%;
}

.right {
  width: 82%;
  height: 100%;
}

.game_list {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 200px;
  cursor: pointer;

}

.game_div {
  height: 230px;
  width: 150px;
  position: relative;
  margin-top: 16px;
  margin-left: 34px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 15px 15px 30px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

}


.game_image {
  width: 100%;
  height: 100%;
}


.game_title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);;
  padding: 5px 0 5px 0;
}


.logo_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #84D02F;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;

}

.slgon {
  margin-top: 10px;
  color: #fff;
  font-weight: bold;
}

.game_log_list {
  margin-top: 30px;
}

.game_log_list_text {
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
}

.game_log_list_content {
  width: 100%;
  cursor: pointer;
}

.game_log_list_div:hover {
  background-color: #59773d;
  color: #ffffff;
  transition: 0.5s;
}

.game_log_list_div {
  width: 100%;
  height: 70px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0 5px 0;
  color: rgba(132, 208, 47, 0.8);
  font-weight: bold;
  font-size: 14px;
}

.game_log_list_image {
  width: 100%;
  height: 100%;
}

.hot_game_log_list_image {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  overflow: hidden;
}

.hot_game_log_list_title {
  width: 50%;
  overflow: hidden;

}

.game_title_one {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-style: italic;
}

.drawer_image {
  width: 100%;
  height: 400px;
  border-radius: 5px;
}

.drawer_title {
  font-weight: bold;
  color: #ffffff;
}

.time {
  width: 100%;
  display: flex;
  color: #ffffff;
  flex-wrap: wrap;
  margin-top: 10px;
  cursor: pointer;
}

.time_div {
  width: 30%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.time_title {
  font-weight: bold;
}

.time_time {
  margin-top: 10px;
}

.title {
  width: 100vw;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.live_drawer {
  width: 100%;
  height: 100%;
}

.live_drawer_game_time {
  display: flex;
  color: #ffffff;
}

.live_drawer_game_title {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.close_div {
  display: flex;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 0;
}

.close_div_div {
  width: 150px;
  height: 60px;
  text-align: center;
}

.close_div_div:nth-child(3) {
  color: #b62424;
}

.custom_image {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
}

.custom_image_image {
  width: 100%;
  height: 100%;
}

.game_file {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  bottom: 80px;
  right: 20px;
  overflow: hidden;
}

.update {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_div img {
  width: 30vw;
  height: auto;
  position: relative;
  top: -7.2vh;
}

.update_div {
  position: relative;
  width: 30vw;
  height: 60vh;
  background-color: #ffffff;
  border-radius: 20px;

}

.update_btn {
  width: 70%;
  height: 7vh;
  background-color: #8445ce;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  font-weight: 500;
  border-radius: 7vh;
  margin-top: 10vh;
}

.nav_box {
  width: 100%;
  height: 40px;

}

.nav_box ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  list-style-type: none;
}

.nav_box ul li {
  margin-right: 20px;
  height: 100%;
  font-weight: bold;
}

.nav_active {
  color: #84D02F;
}

.iframe_pay {
  width: 100%;
  height: 100vh;
  border: medium none;
}
</style>
